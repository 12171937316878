<template>
  <div class="d-flex flex-column flex-root">
    <!--begin::Login-->
    <div
      class="login login-4 d-flex flex-row-fluid login-forgot-on"
      id="kt_login"
    >
      <div
        class="d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat"
        style="background-image: url('media/bg/bg-3.jpg');"
      >
        <div
          class="login-form text-center p-7 position-relative overflow-hidden"
        >
          <!--begin::Login Header-->
          <div class="d-flex flex-center mb-15">
            <a href="#">
              <img src="./logo-letter-13.png" class="max-h-75px" alt="" />
            </a>
          </div>
          <!--end::Login Header-->
          <!--begin::Login forgot password form-->
          <div class="login-forgot">
            <div class="mb-20">
              <h3>¿ Olvidaste tu contraseña ?</h3>
              <div class="text-muted font-weight-bold">
                Ingresa tu email para recuperar tu contraseña
              </div>
            </div>
            <form
              class="form"
              id="kt_login_signin_form"
              @submit.stop.prevent="onSubmitLogin"
              novalidate="novalidate"
            >
              <div class="form-group mb-10">
                <input
                  class="form-control form-control-solid h-auto py-4 px-8"
                  type="text"
                  placeholder="Email"
                  name="email"
                  v-model="form.email"
                  autocomplete="off"
                />
              </div>
              <div class="form-group d-flex flex-wrap flex-center mt-10">
                <button
                  id="kt_login_signin_submit"
                  class="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-2"
                >
                  Solicitar correo
                </button>
              </div>
            </form>
          </div>
          <!--end::Login forgot password form-->
        </div>
      </div>
    </div>
    <!--end::Login-->
  </div>
</template>

<!-- Load login custom page styles -->
<style>
@import "./login-2.css";
</style>

<script>
import { mapState } from "vuex";

import ApiService from "@/core/services/api.service";

export default {
  name: "login-1",
  data() {
    return {
      // Remove this dummy login info
      form: {
        email: ""
      }
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),

    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
      );
    }
  },
  mounted() {
    // Clean localStorage on enter page to avoid conflicts
    localStorage.clear();
  },
  methods: {
    onSubmitLogin() {
      //Clear any previous saves
      localStorage.clear();

      // set spinner to submit button
      const submitButton = document.getElementById("kt_login_signin_submit");
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      ApiService.get(`https://www.maytok.com`).then(() => {
        this.$bvToast.toast(
          "Se enviado el correo de recuperación. Revise su bandeja",
          {
            title: `Correo enviado`,
            variant: "success",
            solid: true
          }
        );
      });

      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    }
  }
};
</script>
